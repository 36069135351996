





























































































































































































.image-button {
  background: linear-gradient(to right, #2840d4, #399ff7);
  color: white;
  width: 180px;
  height: 50px;
  outline: none;
}
.image-button:focus{
  box-shadow: none;
  outline-color: transparent;
  outline: none;
}

/* 设置导航栏文字颜色为黑色 */
.el-menu-item {
    color: black;
  }
  
  /* 设置鼠标悬停或被选中时文字加粗 */
.el-menu-item:hover,.el-menu-item.is-active{
    font-weight: bold;
  }
  .el-menu--horizontal>.el-menu-item.is-active {
    border-bottom: 2px solid #0139d0;
    color: #303133;
}

.el-aside {
    color: #333;
}
::v-deep.el-menu {
  border-bottom: none;
}
// .el-tag + .el-tag {
  
// }
.header {
  background: url("../assets/images/bosscase/Group 2167.png");
  background-size: 100%;
  height: 25rem;
  position: relative;
  z-index: 1;
}
//@import url(); 引入公共css类
.textcenter {
  text-align: center;
  margin-bottom: 50px;
  font-weight: 800;
}
.smoothie {
  transition: all 0.4s ease-in-out;
}
.smo {
  display: block;
  //   height: 360px;
  margin: 20px 0;
  &:hover {
    .hover-effect {
      .img-responsive {
        transform: scale(1);
      }
      .hover-overlay {
        top: 100px;
        opacity: 0;
      }
      .hover-caption {
        opacity: 1;
        transform: scale(1);
        overflow: hidden;
      }
    }
  }

  .hover-effect {
    position: relative;
    overflow: hidden;
    height: inherit;
    .img-responsive {
      width: 100%;
      transform: scale(1.1);
      backface-visibility: hidden;
      display: block;
      max-width: 100%;
      //   height: auto;
      height: 100%;
      vertical-align: middle;
    }

    .hover-caption,
    .hover-overlay {
      position: absolute;
      width: calc(100% - 30px);
      height: calc(100% - 30px);
      top: 15px;
      left: 15px;
      opacity: 0;
      padding: 0 20px;
    }
    .hover-overlay {
      background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.4) 100%
      );
      opacity: 1;
      color: #fff;
    }
    .vertical-align-bottom {
      bottom: 10px;
      position: absolute;
    }
    .vertical-align-top {
      //   position: absolute;
      //   top: 15px;
      //   width: 100%;
      //   box-sizing: border-box;
      margin-top: 20px;
    }

    h4 {
      //   letter-spacing: 5px;
      text-align: left;
      font-size: 20px;
      font-weight: 700;
      width: calc(100% - 40px);
    }
    .item-category-span {
      clear: left;
      background-color: #f64747;
      color: #fff;
      padding: 1px 7px 0;
      display: block;
      float: left;
      margin: 0px 0 20px;
      letter-spacing: 2px;
      font-size: 11px;
      font-weight: 300;
      text-transform: uppercase;
      line-height: 19px;
    }
    .hover-caption,
    .hover-caption .vertical-align-top {
      color: #fff;
      font-size: 14px;
      letter-spacing: 1px;
    }
    .hover-caption {
      transform: scale(0.9);
      background-color: rgba(0, 0, 0, 0.6);
      color: #222;
    }
    .btn-primary {
      background-color: #f64747;
      border-color: #f64747;
    }
  }
}
.header h5 {
  max-width: 600px;
}
